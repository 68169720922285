import { useCallback, useEffect, useState } from 'react';
import { SubscriptionRenewalType, SubscriptionType } from '@remento/types/user';

import { toast } from '@/components/RMToast/RMToast';
import { useServices } from '@/Services';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { captureException } from '@/utils/captureException';

import { SubscriptionManageDialog } from '../components/SubscriptionManageDialog/SubscriptionManageDialog';

import { SubscriptionCancelDialogContainer } from './SubscriptionCancelDialog.container';

export interface SubscriptionManageDialogContainerProps {
  onClose: () => void;
}

export function SubscriptionManageDialogContainer({ onClose }: SubscriptionManageDialogContainerProps) {
  const { userService } = useServices();
  const user = useUser();

  // State
  // This will be updated in the useEffect below
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionType>(SubscriptionType.YEARLY);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

  // Callbacks
  const handleSave = useCallback(async () => {
    try {
      if (user?.subscription?.renewalType == (selectedPlan as never)) {
        onClose();
        return;
      }
      await userService.updateSubscriptionRenewalType(selectedPlan as never as SubscriptionRenewalType);
      toast('Your subscription plan has been updated!');
      onClose();
    } catch (error) {
      captureException(error, true);
      toast('An unexpected error has occurred.', 'root-toast', 'error');
    }
  }, [onClose, selectedPlan, user?.subscription?.renewalType, userService]);

  const handleResumeSubscription = useCallback(async () => {
    try {
      if (user?.subscription == null) {
        throw new Error('invalid-user-subscription');
      }
      await userService.updateSubscriptionRenewalType(user.subscription.type as never as SubscriptionRenewalType);
      toast('Your subscription plan has been resumed!');
      onClose();
    } catch (error) {
      captureException(error, true);
      toast('An unexpected error has occurred.', 'root-toast', 'error');
    }
  }, [onClose, user?.subscription, userService]);

  const handleUpdateBilling = useCallback(async () => {
    try {
      const url = await userService.updateSubscriptionPaymentMethod();
      window.location.assign(url);
    } catch (error) {
      captureException(error, true);
      toast('An unexpected error has occurred.', 'root-toast', 'error');
    }
  }, [userService]);

  // Load the initial selected plan
  useEffect(() => {
    if (user?.subscription == null) {
      return;
    }

    if (user.subscription.renewalType === SubscriptionRenewalType.CANCEL) {
      setSelectedPlan(user.subscription.type);
    } else {
      setSelectedPlan(user.subscription.renewalType as never as SubscriptionType);
    }
  }, [user?.subscription]);

  if (user?.subscription == null) {
    return null;
  }

  return (
    <>
      <SubscriptionManageDialog
        selectedType={selectedPlan}
        activeType={user.subscription.type}
        renewalType={user.subscription.renewalType ?? null}
        subscriptionEndsOn={user.subscription.endsOn}
        onChangeType={setSelectedPlan}
        onSave={handleSave}
        onUpdateBilling={handleUpdateBilling}
        onResumeSubscription={handleResumeSubscription}
        onCancelSubscription={() => setCancelDialogOpen(true)}
        onClose={onClose}
      />

      {cancelDialogOpen && <SubscriptionCancelDialogContainer onClose={() => setCancelDialogOpen(false)} />}
    </>
  );
}
