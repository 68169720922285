import { styled } from '@linaria/react';

import { RMButton } from '@/components/RMButton/RMButton';

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-3xl);

  body[data-mobile='true'] &,
  body[data-tablet='true'] & {
    flex-direction: column;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--spacing-xl);
  max-width: 380px;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-md);
  width: 100%;
`;

export const CancelButton = styled(RMButton)`
  body[data-mobile='true'] & {
    padding: 0;
  }
`;

export const RightContent = styled.div`
  padding-bottom: var(--spacing-2xl);
`;

export const FeaturesTableWrapper = styled.div`
  border-radius: var(--radius-regular);
  border: 1px solid hsla(0, 0%, 89%, 1);
  overflow: hidden;
`;

export const FeaturesTable = styled.table`
  width: 100%;
  background-color: hsla(0, 0%, 100%, 1);
  border-collapse: collapse;

  & th {
    padding: var(--spacing-xs) var(--spacing-md);
    background-color: var(--color-aqua-90);

    body[data-mobile='false'][data-tablet='false'] & {
      white-space: nowrap;
    }
  }

  & td {
    padding: var(--spacing-xs);
  }

  & td:nth-child(2),
  & td:nth-child(3) {
    text-align: center;
  }

  & td,
  & th {
    border-left: 1px solid hsla(0, 0%, 89%, 1);
    border-right: 1px solid hsla(0, 0%, 89%, 1);
  }

  & tr {
    border-bottom: 1px solid hsla(0, 0%, 89%, 1);
  }

  & tbody > tr:last-child {
    border-bottom: none;
  }

  & td:first-child,
  & th:first-child {
    border-left: none;
  }

  & td:last-child,
  & th:last-child {
    border-right: none;
  }
`;
