import { PropsWithChildren } from 'react';
import dayjs from 'dayjs';

import { RMText } from '@/components/RMText/RMText';

import {
  ClickableText,
  ProjectListWrapper,
  StyledMyProjectSettingsPreviewRoot,
} from './MyProjectSettingsPreviewRoot.styles';

export type MyProjectSettingsPreviewProps = PropsWithChildren<{
  isSubscriptionActive: boolean;
  isSubscriptionCancelled: boolean;
  isSubscriptionInactive: boolean;
  subscriptionEndDate: number;
  onManageSubscription: () => void;
}>;

export function MyProjectSettingsPreviewRoot({
  isSubscriptionActive,
  isSubscriptionCancelled,
  isSubscriptionInactive,
  subscriptionEndDate,
  onManageSubscription,
  children,
}: MyProjectSettingsPreviewProps) {
  const formattedEndDate = dayjs(subscriptionEndDate).format('MM/DD/YYYY');

  return (
    <StyledMyProjectSettingsPreviewRoot>
      <RMText type="sans" size="s" color="on-surface-primary">
        {isSubscriptionActive && `Your subscription is active and will be renewed on ${formattedEndDate}.`}
        {isSubscriptionCancelled && `Your subscription is canceled and will expire on ${formattedEndDate}.`}
        {isSubscriptionInactive && `Your subscription ended on ${formattedEndDate}.`}
      </RMText>
      <ClickableText type="sans" size="s" color="primary" underline onClick={onManageSubscription}>
        {isSubscriptionInactive ? 'Reactivate subscription' : 'Manage subscription'}
      </ClickableText>
      <ProjectListWrapper>
        <RMText type="sans" size="s" color="on-surface-primary" bold>
          {isSubscriptionInactive ? 'Expired' : 'Active'} Storytellers & Projects
        </RMText>
        {children}
      </ProjectListWrapper>
      <RMText type="sans" size="s" color="on-surface-primary">
        Questions? Contact{' '}
        <a href="mailto:support@remento.co">
          <RMText type="sans" size="s" color="primary" underline>
            support@remento.co
          </RMText>
        </a>
      </RMText>
    </StyledMyProjectSettingsPreviewRoot>
  );
}
