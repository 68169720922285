import { styled } from '@linaria/react';

import { RMText } from '@/components/RMText/RMText';

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);

  body[data-mobile='true'] & {
    padding: 0 var(--spacing-lg);
  }
`;

export const PlanPickerItem = styled.div`
  display: flex;
  gap: var(--spacing-sm);

  body[data-mobile='true'] & {
    flex-direction: column;
    gap: var(--spacing-xs);
  }
`;

export const PriceText = styled(RMText)`
  body[data-mobile='false'] & {
    margin-left: var(--spacing-xl);
  }
`;

export const ItalicAnchor = styled.a`
  font-style: italic;
  text-decoration: underline;
  text-align: right;

  :visited {
    color: var(--on-surface-primary);
  }
`;

export const ClickableText = styled(RMText)`
  cursor: pointer;
  text-decoration: underline;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: var(--border-hairline);
  margin: var(--spacing-md) 0;
`;

export const ChangePlanWarningWrapper = styled.div`
  padding: var(--spacing-sm) 0;

  body[data-mobile='true'] & {
    padding: var(--spacing-xs) 0;
  }
`;
